import React from 'react';

import LogRocket from 'logrocket';

import SEO from '../components/SEO';

if (
  process.env.NODE_ENV === 'production' &&
  process.env.GATSBY_ACTIVE_ENV !== 'testing' &&
  process.env.LOGROCKET_ID
) {
  LogRocket.init(process.env.LOGROCKET_ID);
}

export default ({ seoTitle, seoDescription, partnerName }) => (
  <React.Fragment>
    <SEO title={seoTitle} description={seoDescription} />
  </React.Fragment>
);
